$erm-dark-green: #0a2b14;
$erm-medium-green: #014e20;
$erm-green: #018219;

$erm-mint: #00ffbc;
$erm-dark-gray: #858a7f;
$erm-light-gray: #d3dfd4;

:root {
  --body-color: #f3f6f9;
}

@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght@400;500;600;700&family=Material+Symbols+Rounded:wght@400;500;600;700&family=Material+Symbols+Sharp:wght@400;500;600;700");
@import "bootstrap";
@import "~react-bootstrap-typeahead/css/Typeahead.css";
@import "~react-datepicker/dist/react-datepicker.css";
@import "typeahead";
@import "dropdown";
@import "datepicker";
@import "modal";
@import "material_symbols";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background-color: var(--body-color);
  margin-top: 58px;
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.15;
}

@media (min-width: 992px) {
  .scroller {
    height: 94vh;
    position: sticky;
    top: 6vh;
    overflow: auto;
  }
}

@media (min-width: 768px) and (max-width: 767px) {
  // Tablet
  .reportTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 767px) {
  // Mobile
  .reportTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.offcanvas-width {
  min-width: 500px;
}

.offline-title {
  font-size: 11px;
  font-weight: bold;
}

.text-warning {
  color: #8c5e00 !important;
}

.custom-clear-button {
  width: 30px;
  height: 30px;
}

.text-capitalize-first {
  display: block;
  &:first-letter {
    text-transform: uppercase;
  }
}

.Main {
  display: flex;
  flex-direction: column;
  min-height: 94vh;
  font-family: "verdana", "cambria", sans-serif;
  font-size: 14px;

  .Footer {
    flex: 0 0 auto;
    margin-bottom: -2px;
  }

  .MainContent,
  .Login,
  .ForgotPassword {
    flex: 1 0 auto;
  }

  .mt-75 {
    margin-top: 75px !important;
  }

  .MainHeader {
    flex: 0 0 auto;
    background-color: white;
    border-bottom: 1px solid #dee2e6;
    @extend .navbar-light;

    &.NavbarOffline {
      @extend .bg-dark;
      @extend .navbar-dark;

      img {
        filter: brightness(0);
      }
    }
  }
}

.dropdown-toggle {
  display: flex;
  align-items: center;
}

.dropdown-icon-style {
  margin-left: auto;
}

.Footer {
  background-color: $erm-dark-green;
}

.has-validation ~ .invalid-feedback {
  display: block;
}

.w-fixed-640 {
  width: 640px !important;
}

.w-fixed-560 {
  width: 560px !important;
}

.w-fixed-320 {
  width: 320px !important;
}

.w-fixed-120 {
  width: 120px !important;
}

.w-fixed-150 {
  width: 150px !important;
}
.w-fixed-250 {
  width: 250px !important;
}

.w-fixed-84 {
  width: 84px !important;
}

.h-fixed-300 {
  height: 300px !important;
}

.h-fixed-360 {
  height: 360px !important;
}

.h-fixed-320 {
  height: 320px !important;
}

.vh-55 {
  height: 55vh !important;
}

.max-height-300 {
  max-height: 300px !important;
}

.min-height-175 {
  min-height: 175px !important;
}

.min-width-auto {
  min-width: auto !important;
}

.min-width-19 {
  min-width: 19% !important;
}

.max-width-200 {
  max-width: 200px !important;
}

.max-width-350 {
  max-width: 350px !important;
}

.fiv-size-md {
  font-size: 1.5em;
}

.w-30 {
  width: 30% !important;
}

.w-65 {
  width: 65% !important;
}

.h-100vh {
  height: 100vh;
}

.h-75vh {
  height: 75vh;
}

.h-calc-100vh-252 {
  height: calc(100vh - 252px) !important;
}

.text-warning-emphasis {
  color: #ffc008;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.cursor-pointer-not-allowed {
  &:hover {
    cursor: not-allowed;
  }
}

.cursor-default {
  &:hover {
    cursor: default !important;
  }
}

.fs-xs {
  font-size: 0.7em !important;
}

.z-10 {
  z-index: 10 !important;
}

// .text-capitalize-first {
//   display: block;
// }
.text-capitalize-first:first-letter {
  text-transform: uppercase;
}

.border-dashed {
  border-style: dashed !important;
}

.custom-prev-arrow {
  border: none;
  cursor: pointer;
  display: block;
  height: 22px;
  position: absolute;
  background-color: transparent;
  bottom: 5%;
  right: 45px;
  width: 20px;
  z-index: 2;
}

.custom-next-arrow {
  border: none;
  cursor: pointer;
  display: block;
  height: 22px;
  position: absolute;
  background-color: transparent;
  right: 20px;
  bottom: 5%;
  width: 20px;
  z-index: 2;
}

.h-calc-120 {
  height: calc(100vh - 120px) !important;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%20-960%20960%20960%22%20width%3D%2224px%22%20fill%3D%22%23018219%22%3E%3Cpath%20d%3D%22M382-240%20154-468l57-57%20171%20171%20367-367%2057%2057-424%20424Z%22%2F%3E%3C%2Fsvg%3E");
}

.btn-close {
  font-size: 12px;
}
.text-pre-wrap {
  white-space: pre-wrap !important;
}